<template>
  <div class="container">
    <div class="create-helmet">
      <h3>Let's create your custom helmet designs!</h3>
    </div>

<!-- //notes / instructions here --!>

    <!-- Color selectors as dropdowns with labels on the same line -->
    <div class="color-selectors">
      <div class="form-group">
        <label for="color1" class="form-label">Color 1</label>
        <select id="color1" v-model="helmetToAdd.color1" class="form-control">
          <option disabled value="">Select Color 1</option>
          <option v-for="color in colors" :key="color" :value="color">{{ color }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="color2" class="form-label">Color 2</label>
        <select id="color2" v-model="helmetToAdd.color2" class="form-control">
          <option disabled value="">Select Color 2</option>
          <option v-for="color in colors" :key="color" :value="color">{{ color }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="color3" class="form-label">Color 3</label>
        <select id="color3" v-model="helmetToAdd.color3" class="form-control">
          <option disabled value="">Select Color 3</option>
          <option v-for="color in colors" :key="color" :value="color">{{ color }}</option>
        </select>
      </div>
    </div>

    <!-- Style dropdown with labels inline 
    <div class="style-dropdown form-group">
      <label for="style" class="form-label">Style</label>
      <select id="style" v-model="helmetToAdd.style" class="form-control">
        <option disabled value="">Select a style</option>
        <option v-for="style in styles" :key="style">{{ style }}</option>
      </select>
    </div>
    -->

    <!-- Sponsor input fields with labels inline -->
    <div class="sponsor-inputs">
      <div class="form-group">
        <label for="sponsor1" class="form-label">Sponsor 1</label>
        <input type="text" id="sponsor1" v-model="helmetToAdd.sponsor1" maxlength="50" class="form-control" placeholder="Enter Sponsor 1">
      </div>

      <div class="form-group">
        <label for="sponsor2" class="form-label">Sponsor 2</label>
        <input type="text" id="sponsor2" v-model="helmetToAdd.sponsor2" maxlength="50" class="form-control" placeholder="Enter Sponsor 2">
      </div>
    </div>

    <!-- Quantity input field with label inline -->
    <div class="quantity-input form-group">
      <label for="quantity" class="form-label">Quantity</label>
      <input type="number" id="quantity" v-model="helmetToAdd.quantity" min="1" max="1000" @input="validateQuantity" class="form-control" placeholder="Enter quantity (max 1000)">
    </div>

    <div class="display-message">
    {{displayMessage1}}
    </div>
    <div class="display-message">
    {{displayMessage2}}
    </div>

    <div class="error-message">
    {{erroryMessage1}}
    </div>
    <div class="error-message">
    {{erroryMessage2}}
    </div>

    <button type="button" class="btn-starterup" @click="handleAddSubmitted()">Create Now</button>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import HelmetService from "../services/HelmetService";

export default {
  data() {
    return {
      rememberMe: true,
      helmetToAdd: {
        color1: '',
        color2: '',
        color3: '',
        style: '',
        sponsor1: '',
        sponsor2: '',
        quantity: 1,  // Default quantity value
        creationPrompt: ''
      },
      // Predefined list of 24 colors
      colors: [
        'Red', 'Green', 'Blue', 'Yellow', 'Purple', 'Orange', 'Pink', 'Brown', 'Black', 'White', 
        'Gray', 'Cyan', 'Magenta', 'Violet', 'Lime', 'Indigo', 'Teal', 'Olive', 'Maroon', 'Navy',
        'Gold', 'Silver', 'Beige', 'Coral'
      ],
      styles: ['Style 1', 'Style 2', 'Style 3', 'Style 4', 'Style 5', 'Style 6', 'Style 7', 'Style 8', 'Style 9', 'Style 10'],
      displayMessage1 : '',
      displayMessage2 : '',
      errorMessage1 : '',
      errorMessage2 : '',
    };
  },
  methods: {
    async handleAddSubmitted() {
      this.modalHelmets = false;

      const currentDate = new Date().getTime();
      this.helmetToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.helmetToAdd);
      console.log(jsonData);
      
      const res = await HelmetService.createHelmets(jsonData);

      if (res.status === 200) {
		if (!res.data.successful) {
			console.log(res.data.message);
			this.errorMessage1 = res.data.message1;
			this.errorMessage2 = res.data.message2; 
		} else {
			console.log(res.data.message);
			this.displayMessage1 = res.data.message1;
			this.displayMessage2 = res.data.message2; 
		}
//		this.$notify({
//		  component: NotificationTemplate,
//		  icon: "tim-icons icon-bell-55",
//		  type: "success",
//		  timeout: 3000,
//		});
      }
    },
    validateQuantity() {
      if (this.helmetToAdd.quantity > 1000) {
        this.helmetToAdd.quantity = 1000;  // Ensure max value of 1000
      }
      if (this.helmetToAdd.quantity < 1) {
        this.helmetToAdd.quantity = 1;  // Ensure minimum value of 1
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #f7f9fc;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.create-helmet h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.color-selectors, .style-dropdown, .sponsor-inputs, .quantity-input {
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-label {
  font-weight: bold;
  flex: 1;
  text-align: left;
}

.form-control {
  flex: 2;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.btn-starterup {
  display: inline-block;
  padding: 12px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-starterup:hover {
  background-color: #0056b3;
}

.error-message {
	color: #f00;
}

</style>
