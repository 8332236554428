import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import CreateHelmet from  '@/pages/CreateHelmet.vue';
import Landing from  '@/pages/Landing.vue';
import MyHelmets from  '@/pages/MyHelmets.vue';
import Helmets from  '@/pages/Helmets.vue';
import HelmetDetail from  '@/pages/HelmetDetail.vue';
import Users from  '@/pages/Users.vue';
import UserDetail from  '@/pages/UserDetail.vue';
import Roles from  '@/pages/Roles.vue';
import RoleDetail from  '@/pages/RoleDetail.vue';
import UserRoles from  '@/pages/UserRoles.vue';
import UserRoleDetail from  '@/pages/UserRoleDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
		redirect: '/landing',
  },
 
  {
    path: "/sign-in",
	name: 'Sign-In',
	layout: DefaultLayout,
    component: () => import("../views/Sign-In.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/sign-up",
	name: 'Sign-Up',
    component: () => import("../views/Sign-Up.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout",
	name: 'Checkout',
	layout: DefaultLayout,
    component: () => import("../views/Checkout.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout-cancel",
	name: 'Checkout-Cancel',
	layout: DefaultLayout,
    component: () => import("../views/Checkout-Cancel.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout-success",
	name: 'Checkout-Success',
	layout: DefaultLayout,
    component: () => import("../views/Checkout-Success.vue"),
    meta: { hideHeader: false },      
  },
  {
    path: "/pricing",
    name: "PricingView",
    component: () => import("../views/PricingView.vue"),
  },
  {
    path: "/checkout/:packageName",
    name: "CheckoutView",
    component: () => import("../views/Sign-Up.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/stripe-checkout",
    name: "StripeCheckoutView",
    component: () => import("../views/StripeCheckoutView.vue"),
  },

	{
		path: "/helmet-gallery",
		name: "HelmetGalleryView",
		layout: DefaultLayout,
		component: Helmets,
	},
	{
		path: '/landing',
		name: 'Landing',
		layout: DefaultLayout,
		component: Landing,
		meta: { hideHeader: false },
	},
	{
		path: '/create',
		name: 'CreateHelmet',
		layout: DefaultLayout,
		component: CreateHelmet,
		meta: { hideHeader: false },
	},
	{
		path: '/my-helmets',
		name: 'MyHelmets',
		layout: DefaultLayout,
		component: MyHelmets,
		meta: { hideHeader: false },
	},

	{
		path: '/users',
		name: 'Users',
		layout: DefaultLayout,
		component: Users,
	},
	{
	    path: '/user/:userId', 
	    name: 'UserDetail',
		layout: DefaultLayout,
	    component: UserDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/roles',
		name: 'Roles',
		layout: DefaultLayout,
		component: Roles,
	},
	{
	    path: '/role/:roleId', 
	    name: 'RoleDetail',
		layout: DefaultLayout,
	    component: RoleDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/userRoles',
		name: 'UserRoles',
		layout: DefaultLayout,
		component: UserRoles,
	},
	{
	    path: '/userRole/:userRoleId', 
	    name: 'UserRoleDetail',
		layout: DefaultLayout,
	    component: UserRoleDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/helmets',
		name: 'Helmets',
		layout: DefaultLayout,
		component: Helmets,
	},
	{
	    path: '/helmet/:helmetId', 
	    name: 'HelmetDetail',
		layout: DefaultLayout,
	    component: HelmetDetail,
	    props: true, // Pass route params as props to the component
    	meta: { hideHeader: true },      
  	},
	{
		path: '/users',
		name: 'Users',
		layout: DefaultLayout,
		component: Users,
	},
	{
	    path: '/user/:userId', 
	    name: 'UserDetail',
		layout: DefaultLayout,
	    component: UserDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/roles',
		name: 'Roles',
		layout: DefaultLayout,
		component: Roles,
	},
	{
	    path: '/role/:roleId', 
	    name: 'RoleDetail',
		layout: DefaultLayout,
	    component: RoleDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/userRoles',
		name: 'UserRoles',
		layout: DefaultLayout,
		component: UserRoles,
	},
	{
	    path: '/userRole/:userRoleId', 
	    name: 'UserRoleDetail',
		layout: DefaultLayout,
	    component: UserRoleDetail,
	    props: true // Pass route params as props to the component
  	},
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
