import http from "../http-common"; 

class HelmetService {
  getAllHelmets(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/helmet/helmets`, searchDTO);
  }
 

  getMyHelmets(searchDTO) {
    console.log(searchDTO)
    let response = this.getRequest(`/helmet/myHelmets`, searchDTO);
	if (response.authStatus) {
		return response;
	} else {
		console.log("authStatus is FALSE. Redirect to SignIn")
		return response;
	}
    
  }

  findHelmetId(data) {
    return this.postRequest(`/helmet/findHelmetId`, data);
  }

  rateHelmet(data) {
    return this.postRequest("/helmet/rateHelmet", data);
  }

  addToPrompt(data) {
    return this.postRequest("/helmet/addToPrompt", data);
  }

  createHelmets(data) {
    return this.postRequest("/helmet/createHelmets", data);
  }

  get(helmetId) {
    return this.getRequest(`/helmet/${helmetId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/helmet?field=${matchData}`, null);
  }

  addHelmet(data) {
    return http.post("/helmet/addHelmet", data);
  }

  update(data) {
  	return http.post("/helmet/updateHelmet", data);
  }
  
  uploadImage(data,helmetId) {
  	return http.postForm("/helmet/uploadImage/"+helmetId, data);
  }


	uploadAutoGeneratedImage(data,helmetId) {
		return this.postRequest("/helmet/uploadAutoGeneratedImage/"+helmetId, data);
	}
	generateImage(data) {
		return this.postRequest("/images/generate", data);
    };


	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new HelmetService();
