import axios from "axios"; 

export default axios.create({
  baseURL: "https://myhelmets.ai:8080/",  //SET TO THIS baseURL when deploying to VPS
//  baseURL: "http://localhost:8089/",  //local
  headers: {
    "Content-type": "application/json",
  },
});


