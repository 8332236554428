<template>
  <div class="landing-page">

    <!-- Section 1 
    <section class="section section-1">
    	<div class="content">
	      <h1 class="headline">Fast Forward to the Future of Application Building</h1>
      <router-link class="link" to="/create">
        <button type="button" class="btn-starterup" >One Prompt Away!</button>
      </router-link>
	      <div class="messaging-points">
	        <div class="message">
				<div class="image-container">
				  <img src="images/networked-computers.png"/>
				</div>
	          <div>Build amazing full-stack applications using only a single prompt</div>
	        </div>
	        <div class="message">
				<div class="image-container">
				  <img src="images/rocket3.png"/>
				</div>
	          <div>Launch your next PoC in seconds without writing a single line of code</div>
	        </div>
	        <div class="message">
				<div class="image-container">
				  <img src="images/iterate.png"/>
				</div>
	          <div>Iterate and customize with zero limitations using your domain's nomenclature</div>
	        </div>
	      </div>
    	</div class="content">
    </section>-->

    <section class="section section-2">
      <div class="content">
	  	  <h1 class="headline">Want a new paint scheme concept before sending your helmet to the painter? Let's create some designs!</h1>
	      <div class="messaging-points">
	        <div class="message">
				<div class="image-container">
				  <img class="perfect-for-image" src="images/helmet-001.png"/>
				</div>
	          <div><b>Step 1.</b> Select 3 colors</div>
	        </div>
	        <div class="message">
				<div class="image-container">
				  <img class="perfect-for-image" src="images/helmet-004.png"/>
				</div>
	          <div><b>Step 2.</b> Enter 2 sponsors</div>
	        </div>
	        <div class="message">
				<div class="image-container">
				  <img class="perfect-for-image" src="images/helmet-003.png"/>
				</div>
	          <div><b>Step 3.</b> Pick your favorite lids</div>
	        </div>
	      </div>
		</div>
    </section>

  <div class="pricing-view">
    <!-- <app-header /> -->
    <div class="content">
      <div class="topbar">
        <h2 class="title">Our Pricing</h2>
        <p class="label">
          Find your next lid design by the end of day.
        </p>
      </div>
      <div class="pricing-table-wrapper">
        <div class="card" v-for="(card, index) in packages" :key="index">
          <p class="card-title">{{ card.name }}</p>
          <p class="card-price">${{ activePackageType === "annual" ? card.price * 6 : card.price }}</p>
          <p class="card-frequency">{{ card.frequency }}</p>
          <div class="packages-list">
            <div class="package" v-for="(pkg, i) in card.package" :key="i">
              <img
                v-if="pkg.access"
                src="../assets/access-icon.png"
                alt="icon"
                class="package-icon"
              />
              <img
                v-else
                src="../assets/not-access-icon.png"
                alt="icon"
                class="package-icon"
              />
              <span class="package-label">{{ pkg.name }}</span>
            </div>
          </div>
          <p class="card-icp">{{ card.icp }}</p>
          <p class="card-tagline">{{ card.tagline }}</p>
          <app-button
            label="SignUp and Buy"
            class="buy-btn"
            @click="handleBuy(card)"
          />
        </div>
      </div>
    </div>
  </div>


    <section class="section section-3">
      <div class="content">
      	<h1 class="headline">Custom paint is expensive.<br> Custom designs don't have to be.</span></h1>
      	<div class="messaging-points">
	        <div class="message">
				<div class="image-container">
				  <img class="perfect-for-image" src="images/helmet-001.png"/>
				</div>
	          <div><b></b>Red-White-Gray</div>
	        </div>
	        <div class="message">
				<div class="image-container">
				  <img class="perfect-for-image" src="images/helmet-004.png"/>
				</div>
	          <div><b></b>Yellow-Black-White</div>
	        </div>
	        <div class="message">
				<div class="image-container">
				  <img class="perfect-for-image" src="images/helmet-003.png"/>
				</div>
				<div><b></b>Blue-Magenta-Purple</div>
	        </div>
	        <div class="message">
				<div class="image-container">
				  <img class="perfect-for-image" src="images/helmet-007.png"/>
				</div>
	          <div><b></b>Yellow-Gray-White</div>
	        </div>
		</div>
	  </div>
    </section>

				<div class="help-section">
					<p class="font-semibold text-muted" style="margin-top:1px">Need help?</p>
					<a href="mailto:ennis@witnessracing.com"> Email Us</a>
				</div>

  </div>


</template>
<script>
import json from "@/json/data.json";
import AppButton from "@/components/AppButton.vue";
export default {
  name: "PricingView",
  components: {
    "app-button": AppButton,
  },
  data() {
    return {
      packageTypes: ["monthly", "annual"],
      activePackageType: "monthly",
      packages: json.packages,
    };
  },
  methods: {
    handleBuy(card) {
      this.$router.push({
        name: "CheckoutView",
        params: { packageName: card.name },
      });
    },
  },
};
</script>

<style scoped>
.pricing-view .content {
	margin-top: 0px !important;
}

.topbar {
  height: 400px;
  background: rgb(65, 9, 121);
  background: linear-gradient(
    90deg,
    rgba(65, 9, 121, 1) 0%,
    rgba(121, 9, 118, 0.8939950980392157) 55%
  );
  color: white;
}

.title {
  font-size: 22px;
  padding-top: 2.5rem;
  color: #ffffff;
}

.label {
  font-size: 18px;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
}
.package-types {
  width: 300px;
  margin: 3rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #000;
  gap: 20px;
  padding: 12px 0;
  border-radius: 4px;
}
.package-type {
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 0.04rem;
  text-transform: capitalize;
}

.active {
  color: #0000ff;
}
.pricing-table-wrapper {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: -250px;
  flex-wrap: wrap;
}

.card {
  min-width: 400px;
  color: #444;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 2rem rgba(51, 51, 51, 0.2);
  padding: 1.5rem;
  background: #fff;
}

.card-title {
  font-size: 12px;
  background: #ccc;
  width: max-content;
  margin: 0 auto;
  padding: 6px 10px;
  text-transform: uppercase;
  border-radius: 1rem;
  font-weight: 600;
}
.card-price {
  font-size: 42px;
  font-weight: 600;
  margin-top: 14px;
}

.card-frequency {
  font-size: 18px;
  font-weight: 600;
  margin-top: 14px;
}

.packages-list {
  margin-top: 24px;
}

.package {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.package-icon {
}

.package-label {
  margin-left: 8px;
}

.buy-btn {
  margin-top: 12px;
  width: 100%;
}

.link {
  color: #fff;
  font-size: 16px;
}

.link:hover {
  color: #0000ff;
}
p,
h2 {
  margin: 0;
  padding: 0;
}

/* General page layout */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Helvetica", "Arial", sans-serif;
}

/* Section styling */
section {
  width: 100vw; /* Full viewport width */
  margin: 0;    /* Remove default margin */
  padding: 50px 20px; /* Increased padding for visibility */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.section-1 {
  background-color: black;
  color: white;
  text-align: center;
  font-weight: 400;
  opacity: 1;
}

.section-3 {
  background-color: rgba(245, 245, 245, 1);
  color: black;
  text-align: center;
  font-weight: 400;
  opacity: 1;
}

.section-5 {
  background-color: rgba(245, 245, 245, 1);
  color: black;
  text-align: center;
  font-weight: 400;
  opacity: 1;
}

.section-7 {
  background-color: rgba(245, 245, 245, 1);
  color: black;
  text-align: center;
  font-weight: 400;
  opacity: 1;
}

.section-1 .headline {
  font-size: 3.5rem;
  color: white;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: bold;
  line-height: 70px
}

.section-2 .headline {
  max-width: 750px;
  font-size: 2.0rem;
  color: black;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: medium;
}

.section-3 .headline {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;
  //font-style: italic;
  font-weight: bold;
}

.section-4 .headline {
  font-size: 2rem;
  color: rgba(0,97,249,1);
  margin-bottom: 20px;
  font-style: italic;
  font-weight: bold;
}

.section-5 .headline {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;
  //font-style: italic;
  font-weight: bold;
}

.section-6 .headline {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;
  //font-style: italic;
  font-weight: bold;
}

.section-7 .headline {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;
  //font-style: italic;
  font-weight: bold;
}

.section-8 .headline {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;
  //font-style: italic;
  font-weight: bold;
}

.section-2 .subheadline {
  font-size: 1.4rem;
  color: black;
  margin-bottom: 20px;
  font-weight: bold;
}

.section .content {
  max-width: 1000px;
  display: flex;
  flex-direction: column; /* Change to column for centering */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text */
  margin: 0 auto; /* Center the content block */
 }

.messaging-points {
  display: flex;
  flex-direction: row; /* Default to row layout */
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  font-size: 1.3rem;
}

.message {
  flex: 1;
  max-width: 280px;
  text-align: center;
}

.reviews {
  display: flex;
  flex-direction: default; /* Default to row layout */
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  font-size: 1.3rem;
}

.review {
  max-width: 300px;
  background-color: white;      /* Set background to white */
  border-radius: 10px;         /* Add rounded corners */
  margin: 10px;                /* Add spacing around each review */
  padding: 15px;               /* Optional: Add padding inside the review */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow for a card effect */
  color: rgba(51, 51, 51, 1);
  font-size: .85rem;
  text-align: justify;
}

.reviewer {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}

.review-image {
  padding: 10px 0px 0px 0px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}

.review-image img {
  border-radius: 50%;
}

.reviewer-title {
	font-weight: normal;
}

.icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #007bff;
}

.btn-starterup {
    display: inline-block;
    padding: 12px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.section-1 .btn-starterup {
	margin-top: 0px; 
	margin-bottom: 20px;
}
 
.image-container {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 16px; /* Applies to all corners */
  overflow: hidden;    /* Ensures any overflow is hidden */
}

.section-1 .message .image-container img {
  width: 80px;
  height: auto;
  display: block;
  margin: 20px auto 0px ; /*top LandR bottom*/
}

.perfect-for-image {
  width: 100%;        /* Ensures the image takes the full width of the container */
  height: auto;       /* Maintains the aspect ratio */
  display: block;     /* Center the image in the block */
}

.questions {
  display: flex;
  flex-direction: default; /* Default to row layout */
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  font-size: 1.3rem;
}

.question {
  max-width: 800px;
  background-color: white;      /* Set background to white */
  border-radius: 10px;         /* Add rounded corners */
  margin: 10px;                /* Add spacing around each review */
  padding: 15px;               /* Optional: Add padding inside the review */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow for a card effect */
  color: rgba(51, 51, 51, 1);
  font-size: .85rem;
  text-align: justify;
}

.question-text {
	font-weight: bold;
	font-size: 1.1rem;
}

.answer-text {
	font-size: 1rem;
}

.gradient-box {
  max-width: 1024px;
  max-height: 240px;
  background: linear-gradient(80deg, rgba(50, 134, 250, 1) 0%, rgba(250, 50, 50, 1) 100%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
}

.box-text {
  font-size: 2rem;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 40px;
  font-weight: bold;
  color: white;
}

.box-button {
  font-size: 16px;
}


/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .messaging-points {
    flex-direction: column; /* Change to column layout */
    align-items: center; /* Center items horizontally */
  }

  .section-1 .headline {
    font-size: 2rem; /* Smaller font size when stacked */
    margin-bottom: 40px; /* Increased space below headline */
  }

  .section-2 .headline {
    font-size: 1.5rem; /* Smaller font size when stacked */
    margin-bottom: 40px; /* Increased space below headline */
    margin-left: 40px;
    max-width: 400px;
  }

  .section-3 .headline {
    font-size: 1.5rem; /* Smaller font size when stacked */
    margin-bottom: 40px; /* Increased space below headline */
  }
  
  .section-1 .subheadline {
    font-size: 1.2rem; /* Smaller font size when stacked */
    margin-bottom: 40px; /* Increased space below headline */
  }
  
  .section-2 .subheadline {
    font-size: 1.2rem; /* Smaller font size when stacked */
    margin-bottom: 40px; /* Increased space below headline */
  }

  .section-3 .subheadline {
    font-size: 1.2rem; /* Smaller font size when stacked */
    margin-bottom: 40px; /* Increased space below headline */
  }
  
	.message {
	  max-width: 480px;
	}
	
	.question {
		max-width: 600px;
	}  

.gradient-box {
	max-width: 600px;
	max-height: 300px;
	}
}

.help-section {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px; /* Space between text and button */
	margin-top:10px; 
	margin-bottom: 10px;	
}

</style>